$(document).ready(function() {
	$('#supportform').validate({
		rules: {
			name: {
				required: true
			},
			email: {
				required: true,
				email: true
			},
			subject: {
				required: true
			},
			message: {
				required: true
			}
		},
		messages: {
			name: {
				required: 'Please enter your name.'
			},
			email: {
				required: 'Please enter your email.',
				email: 'Please enter a valid email address.'
			},
			subject: {
				required: 'Please enter subject.'
			},
			message: {
				required: 'Please enter message.'
			}
		},
		submitHandler: submitHandlerSupport
	});

	$('#support').on('hidden.bs.modal', function() {
		$('#support .notify').html('');
	});
});

function submitHandlerSupport(form) {
	disableForm(form);
	$(form).ajaxSubmit({
		dataType: 'json',
        success: formResponseSupport,
        error: formResponseError
    });
}

function formResponseSupport(responseText, statusText) {
	var form = $('#supportform');
    enableForm(form);
    var notify = $(form).find('.notify');
	if(statusText == 'success') {
		if(responseText.type == 'success') {
			resetForm(form);
			showSuccess(notify, responseText.caption, responseText.errormessage, function() {
                setTimeout(function() {
                	$('#support').modal('hide');
                }, 5000);
            });
		}
		else {
			showError(notify, responseText.caption, responseText.errormessage);
            if(responseText.errorfields !== undefined) {
                highlightInvalidFields(form, responseText.errorfields);
            }
		}
	}
    else {
		showError('Unable to communicate with server.');
	}
}