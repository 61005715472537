$(document).ready(function() {

	$.ajaxSetup({
        headers: {
          'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

	jQuery.validator.setDefaults({
		debug: false,
		onsubmit: true,
		onfocusout: false,
		onkeyup: false,
		onclick: false,
		errorPlacement: function(error, element) { },
		invalidHandler: invalidHandler,
		highlight: highlightElement,
		unhighlight: unhighlightElement,
		submitHandler: submitHandler
	});

	var url = window.location.href;
	var idx = url.indexOf('#');
	if(idx != -1) {
		var hash = url.substring(idx+1);
		console.log(hash);
		console.log($('[data-id="'+hash+'"]'));
		setTimeout(function() {
			scrollwindowToElement($('[data-id="'+hash+'"]'));
		}, 1000);

	}

});

function invalidHandler(event, validator) {
	if(validator.errorList.length == 0) return;
	var notify = $(validator.currentForm).find('.notify');
	showError(notify, 'One or more invalid inputs found.', validator.errorList);
}

function highlightElement(element) {
	$(element).closest('.form-group').addClass('has-error');
}

function unhighlightElement(element) {
	$(element).closest('.form-group').removeClass('has-error');
}

function highlightInvalidFields(form, fieldnames) {
	$(form).find('input, select, textarea').each(function() {
		var fieldname = $(this).attr('name');
		var index = $.inArray(fieldname, fieldnames);
		if(index == -1) {
			unhighlightElement($(this));
		}
		else {
			highlightElement($(this));
		}
	});
}

function submitHandler(form) {
	disableFormButton(form);
	showLoader();
	$(form).ajaxSubmit({
		dataType: 'json',
        success: formResponse,
        error: formResponseError
    });
}

function formResponseError (XMLHttpRequest, textStatus, errorThrown) {
	hideLoader();
	showError($('form').find('.notify'), 'Something went wrong. Please try again.');
	enableForm($('form'));
	console.log('ERROR: ' + XMLHttpRequest.statusText);
	console.log('ERROR: ' + XMLHttpRequest.errorThrown);
}

function disableForm(form) {
	$(form).addClass('form-submitted-overlay');
	var buttons = $(form).find('button[type="submit"]');
	$(buttons).attr('disabled', 'disabled');
	$(buttons).each(function() {
		if(
			$(this).attr('data-text-default') !== undefined &&
			$(this).attr('data-text-loading') !== undefined
		) {
			var loadingtext = $(this).attr('data-text-loading');
			$(this).html(loadingtext);
		}
	});
}

function enableForm(form) {
	$(form).removeClass('form-submitted-overlay');
	var buttons = $(form).find('button[type="submit"]');
	$(buttons).removeAttr('disabled');
	$(buttons).each(function() {
		if(
			$(this).attr('data-text-default') !== undefined &&
			$(this).attr('data-text-loading') !== undefined
		) {
			var defaulttext = $(this).attr('data-text-default');
			$(this).html(defaulttext);
		}
	});
}

function showSuccess(notify, caption, successList, callback) {

	var successListMsgs = '';

	if(successList && successList.length > 0){
		successListMsgs += '<ul>';
		for(var i = 0; i < successList.length; i++) {
		    successListMsgs += '<li>'+successList[i]+'</li>';
	    }
		successListMsgs += '</ul>';
	}

	if($(notify).html() != '') {
		$(notify).fadeOut(300, function() {
			showSuccessFadeIn(notify, caption, successListMsgs);
		});
	}
	else {
		showSuccessFadeIn(notify, caption, successListMsgs);
	}


	if(callback && typeof callback == 'function') {
		setTimeout(function() {
			callback();
		}, 3000);
	}
}

function showSuccessFadeIn(notify, caption, successListMsgs) {
	$(notify).hide().html(
		'<div class="alert alert-dismissible alert-success" role="alert">'+
			'<button type="button" class="close" data-dismiss="alert" aria-label="Close">'+
				'<span class="fa fa-times-circle"></span>'+
			'</button>'+
			'<div class="alert-title">'+
				'<i class="fa fa-check"></i> '+caption+
			'</div>' +
			successListMsgs +
		'</div>'
	 ).fadeIn(300);
}

function showError(notify, caption, errorList) {

	var errorListMsgs = '';

	if(errorList && jQuery.type(errorList) == 'array' && errorList.length > 0) {
		if(errorList.length > 3) {
			caption = 'Please fill in all the required fields.';
		}
		else {
			errorListMsgs += '<ul>';
			for(var i = 0; i < errorList.length; i++) {
			    errorListMsgs += '<li>'+errorList[i].message+'</li>';
		    }
			errorListMsgs += '</ul>';
		}
	}
	else if(errorList && jQuery.type(errorList) == 'string') {
		errorListMsgs += '<ul><li>'+errorList+'</li></ul>';
	}
	if($(notify).html() != '') {
		$(notify).fadeOut(300, function() {
			showErrorFadeIn(notify, caption, errorListMsgs);
		});
	}
	else {
		showErrorFadeIn(notify, caption, errorListMsgs);
	}
}

function showErrorFadeIn(notify, caption, errorListMsgs) {
	$(notify).hide().html(
		'<div class="alert alert-dismissible alert-danger" role="alert">'+
			'<button type="button" class="close" data-dismiss="alert" aria-label="Close">'+
				'<span class="fa fa-times-circle"></span>'+
			'</button>'+
			'<div class="alert-title">'+
				'<i class="fa fa-exclamation-triangle"></i> '+caption+
			'</div>' +
			errorListMsgs +
		'</div>'
	 ).fadeIn(300);
}

function resetForm(form) {
	$(form).get(0).reset();
}

function refreshPage() {
	window.location.href = '';
}

function showLoader() {
    $('#loader').stop().show();
}

function hideLoader() {
	$('#loader').stop().hide();
}

function scrollwindowTop() {
	$(document).scrollTop(0);
}

function scrollwindowToElement(element) {
	if($(element).length > 0) {
		var top = $(element).offset().top;
		var headerheight = $('.header-menu').height();
		$('html, body').animate({'scrollTop': (top - headerheight)}, 400);
	}
}

function baseurl() {
	return $.trim($('meta[name="base-url"]').attr('content'));
}

function ajaxUpdate(Url, data, callBack, noLoader) {
	if(!noLoader) {
		showLoader();
	}
	$.ajax({
		type: 'POST',
		url: Url,
		cache: false,
		data: data,
		dataType: 'json',
		success: callBack,
		error: formResponseError
	});
}

function ajaxFetch(Url, data, callBack, noLoader){
	if(!noLoader) {
		showLoader();
	}
	$.ajax({
		type:'POST',
		url:Url,
		data:data,
		dataType:'html',
		success:callBack,
		error: formResponseError
	});
}