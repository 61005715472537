$(document).ready(function() {
	$('.democontactusform').each(function() {
		$(this).validate({
	        rules: {
	            name: {
	                required: true
	            },
	            restaurantname: {
	                required: true
	            },
	            phoneno: {
	                required: true
	            },
	            email: {
	                required: true,
	                email: true
	            }
	        },
	        messages: {
	            name: {
	                required: 'Please enter your full name.'
	            },
	            restaurantname: {
	                required: 'Please enter your restaurant name.'
	            },
	            phoneno: {
	                required: 'Please enter your phone number.'
	            },
	            email: {
	                required: 'Please enter your email address.',
	                email: 'Please enter a valid email address.'
	            }
	        },
	        submitHandler: submitHandlerScheduleDemo
	    });
	});
});

function submitHandlerScheduleDemo(form) {
	var notify = $(form).find('.notify');
    disableForm(form);
    showLoader();
    $(notify).hide();
    $(form).ajaxSubmit({
        dataType: 'json',
        success: function (responseText, statusText) {
		    var notify = $(form).find('.notify');
		    hideLoader();
		    enableForm(form);
		    if(statusText == 'success') {
		        if(responseText.type == 'success') {
		            resetForm(form);
		            showSuccess(notify, responseText.caption, '', null, 'alertify');
		        }
		        else {
		            showError(notify, responseText.caption, responseText.errormessage);
		            if(responseText.errorfields !== undefined) {
		                highlightInvalidFields(form, responseText.errorfields);
		            }
		        }
		    }
		    else {
		        showError('Unable to communicate with server.');
		    }
		},
        error: formResponseError
    });
}

