$(document).ready(function() {

	set_window_height_elements();
	set_owl_slider();
	set_scroll();

	var lightbox = new Lightbox();
	lightbox.load();

	new WOW().init();

});

function set_window_height_elements() {
	if($('.set-window-height').length > 0) {
		$('.set-window-height').css({
			height: ($(window).height() - $('.header').height()) + 'px'
		});
	}
}

function set_owl_slider() {
	if($('.owl-carousel-slider').length > 0) {
		var time = 7;
		var $progressBar,
			$bar,
			$elem,
			isPause,
			tick,
			percentTime;

		$('.owl-carousel-slider').find('.owl-carousel').owlCarousel({
			slideSpeed : 500,
			paginationSpeed : 500,
			singleItem : true,
			navigation : true,
			navigationText: [
				'<i class="fa fa-angle-left"></i>',
				'<i class="fa fa-angle-right"></i>'
			],
			afterInit : progressBar,
			afterMove : moved,
			startDragging : pauseOnDragging,
			//autoHeight : true,
			transitionStyle : 'fadeUp'
		});

		function progressBar(elem) {
			$elem = elem;
			buildProgressBar();
			start();
		}

		function buildProgressBar() {
			$progressBar = $('<div>', {
				class: 'progressBar'
			});
			$bar = $('<div>',{
				class: 'bar'
			});
			$progressBar.append($bar).appendTo($elem);
		}

		function start() {
			percentTime = 0;
			isPause = false;
			tick = setInterval(interval, 10);
		};

		function interval() {
			if(isPause === false) {
				percentTime += 1 / time;
				$bar.css({
					width: percentTime + '%'
				});
				if(percentTime >= 100){
					$elem.trigger('owl.next')
				}
			}
		}

		function pauseOnDragging() {
			isPause = true;
		}

		function moved() {
			clearTimeout(tick);
			start();
		}
	}
}

function set_scroll() {
	$(window).scroll(function(event) {
		var winTop = $(window).scrollTop();
		if(winTop > 42) {
			$('.back-top').show();
			$('html').addClass('fixed-header');
		}
		else {
			$('.back-top').hide();
			$('html').removeClass('fixed-header');
		}
	});
}